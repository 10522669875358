export default {
    props: {
        value: {

        },
        label: {
            type: String
        },
        errorMessages: {

        },
        clearable: {
            type: Boolean
        },
    },
    data(){
        return {
            items: [],
            itemsAutocompleteTimer: '',
            query: '',
            autocompleteRoute: ''
        }
    },
    mounted() {
        if (this.inputValue){
            this.itemAutocomplete(this.inputValue);
        }
    },
    methods: {
        itemAutocomplete(query){
            if (query !== this.query){
                this.query = query;
                clearTimeout(this.itemsAutocompleteTimer);
                this.itemsAutocompleteTimer = setTimeout(() => {
                    axios.get(this.route(this.autocompleteRoute), {params: {
                            query: query,
                        }}).then( response => {
                        this.items = response.data;
                    });
                }, 100);
            }
        },
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    }
}