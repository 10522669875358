<template>
    <v-autocomplete
        :items="items"
        v-model="inputValue"
        :label="label"
        :error-messages="errorMessages"
        :clearable="clearable"
        @update:search-input="itemAutocomplete"
        @change="$emit('change')"
    ></v-autocomplete>
</template>

<script>
    import autocomplete from '@/mixins/components/autocomplete'
    export default {
        name: "UserAutocomplete",
        mixins: [autocomplete],
        data(){
            return {
                autocompleteRoute: 'admin.users.autocomplete'
            }
        },
    }
</script>

<style scoped>

</style>
