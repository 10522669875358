import hasOptions from "@/mixins/hasOptions";

export default {
    props: {
        id: {
            type: [String, Number, null],
        },
        editMode: {
            type: Boolean,
            default: false,
        }
    },
    mixins: [hasOptions],
    data(){
        return {
            showRoute: '',
            storeRoute: '',
            updateRoute: '',

            newTitle: '',
            editTitle: '',

            loading: false,
            loaded: false,
            editing: false,
            item: null,
            options: {},
            preloadOptions: null,
            errors: {},
        }
    },
    created() {
        if (this.preloadOptions !== null){
            if(this.id){
                this.loadOptions(this.preloadOptions).then( () => {
                    this.initialize();
                });
            } else {
                this.loadOptions(this.preloadOptions).then( () => {
                    this.loaded = true;
                });
            }
        } else {
            if(this.id){
                this.initialize();
            }
        }
    },
    methods: {
        initialize(){
            this.loading = true;
            axios.get(this.route(this.showRoute, this.id)).then( response => {
                this.item = response.data;
                this.loaded = true;
                if (this.editMode){
                    this.editing = true;
                }
            }).catch( () => {

            }).then( () => {
                this.loading = false;
            })
        },
        save() {
            if (!this.id) {
                axios.post(this.route(this.storeRoute), this.item).then(() => {
                    console.log('save success');
                    this.$emit('saved');
                }).catch( (err) => {
                    this.errors = err.response.data.errors;
                    console.log(err.response.data);
                })
            } else {
                axios.post(this.route(this.updateRoute, this.id), this.item).then(() => {
                    console.log('save success');
                    this.$emit('saved');
                }).catch( (err) => {
                    this.errors = err.response.data.errors;
                    console.log(err.response.data);
                })
            }
        },

    },
    computed: {
        formTitle() {
            return !this.id
                ? (this.newTitle ? this.trans(this.newTitle) : '')
                : (this.editTitle ? this.trans(this.editTitle) : '');
        },

    }

}
