<template>

    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
            <span v-on="on"><span v-text="id" ></span><span v-if="email"> :: {{ email }}</span></span>
        </template>
        <user-card :id="id" v-if="dialog"></user-card>
    </v-dialog>

</template>

<script>
    import UserCard from "@/components/admin/user/UserCard";
    export default {
        name: "UserLink",
        components: {UserCard},
        props: {
            userId: {
                type: [Number, String],
            },
            userEmail: {
                type: [String],
            },
            user: {
                type: [Object],
            },
        },
        data(){
            return {
                dialog: false,
            }
        },
        computed:{
            id(){
                if (this.userId){
                    return this.userId;
                } else if (this.user && this.user.id){
                    return this.user.id;
                } else {
                    return '';
                }
            },
            email(){
                if (this.userEmail){
                    return this.userEmail;
                } else if (this.user && this.user.email){
                    return this.user.email;
                } else {
                    return '';
                }
            }
        }

    }
</script>

<style scoped>

</style>