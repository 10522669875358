export default {
    props: {
        predefinedFilters: {
            type: Object,
        }
    },
    data(){
        return {
            filterTimer: null,
            filterShow: null,
            filters: {},
            filtersDefault: {},
        }
    },
    created() {
        this.filtersDefault = JSON.parse(JSON.stringify(this.filters));
        if (this.predefinedFilters){
            _.each(this.predefinedFilters, (value, name) => {
                this.filtersDefault[name] = value;
            })
        }
    },
    methods: {
        clearFilters(){
            this.filters = JSON.parse(JSON.stringify(this.filtersDefault));
            this.initialize();
        },
        applyFilters(){
            clearTimeout(this.filterTimer)
            this.initialize();
        },
        customFilter (item, queryText, itemText) {
            const textOne = itemText.toLowerCase().replace(new RegExp(/[-\s]+/, "gi"), '');
            const textTwo = item.value;
            const searchText = queryText.toLowerCase().replace(new RegExp(/[-\s]+/, "gi"), '');

            var arr = [
                {ru: "й", en: "q"},{ru: "ц", en: "w"},{ru: "у", en: "e"},{ru: "к", en: "r"},
                {ru: "е", en: "t"},{ru: "н", en: "y"},{ru: "г", en: "u"},{ru: "ш", en: "i"},
                {ru: "щ", en: "o"},{ru: "з", en: "p"},{ru: "х", en: "["},{ru: "ъ", en: "]"},
                {ru: "ф", en: "a"},{ru: "ы", en: "s"},{ru: "в", en: "d"},{ru: "а", en: "f"},
                {ru: "п", en: "g"},{ru: "р", en: "h"},{ru: "о", en: "j"},{ru: "л", en: "k"},
                {ru: "д", en: "l"},{ru: "ж", en: ";"},{ru: "э", en: "'"},{ru: "я", en: "z"},
                {ru: "ч", en: "x"},{ru: "с", en: "c"},{ru: "м", en: "v"},{ru: "и", en: "b"},
                {ru: "т", en: "n"},{ru: "ь", en: "m"},{ru: "б", en: ","},{ru: "ю", en: "."}
            ];
            function langSwitch(text, lang){
                for (var i = 0; i<arr.length; i++){
                    if(lang == "ru" && text.indexOf(arr[i].en) != -1){
                        text = text.replace(new RegExp(arr[i].en, "gi"), arr[i].ru);
                    }else if(lang == "en" && text.indexOf(arr[i].ru) != -1){
                        text = text.replace(new RegExp(arr[i].ru, "gi"), arr[i].en);
                    }
                }
                return text;
            }
            const searchTextTwo = langSwitch(searchText, 'ru');
            const searchTextThree = langSwitch(searchText, 'en');


            return textOne.indexOf(searchText) > -1 || textTwo == searchText || textOne.indexOf(searchTextTwo) > -1 || textOne.indexOf(searchTextThree) > -1
        },
    },
    watch: {
        filters: {
            deep: true,
            handler(){
                clearTimeout(this.filterTimer)
                this.filterTimer = setTimeout(() => {
                    this.initialize();
                }, 1000)
            }
        },
    },
    computed: {

    }

}
