<template>
    <v-card
            :loading="loading"
            v-if="!editing"
    >
        <v-card-title v-if="loaded">
            <span class="headline">{{ item.id }} :: {{ item.email }}</span>
        </v-card-title>

        <v-card-text v-if="loaded">
            <v-list two-line>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>{{ trans('fields.common.plan') }}</v-list-item-subtitle>
                        <v-list-item-title>{{ item.plan.translations[$store.getters.curlocale] }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>{{ trans('fields.common.planPeriod') }}</v-list-item-subtitle>
                        <v-list-item-title>{{ options.planPeriodsKeyed[item.plan_period] }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>{{ trans('fields.plan.valid_till') }}</v-list-item-subtitle>
                        <v-list-item-title>
                            <plan-valid-till :item="item"></plan-valid-till>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>{{ trans('fields.common.balance') }}</v-list-item-subtitle>
                        <v-list-item-title>{{ item.balance }} RUB</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>{{ trans('pages.plan.payment_method') }}</v-list-item-subtitle>
                        <v-list-item-title>
                            <template
                                    v-if="item.billing_data"
                            >
                                {{ trans('pages.plan.attachedCard', {mask: item.billing_data.card_mask}) }} {{ item.billing_data.expire | dateFormat('YYYY-MM') }}
                            </template>
                            <template
                                    v-else
                            >
                                {{ trans('pages.plan.noPaymentMethod') }}
                            </template>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <template v-if="item.next_plan_id">
                    <v-col cols="12">{{ trans('pages.plan.changePlanQueued', {date: $options.filters.dateFormat(item.plan_valid_till, 'YYYY-MM-DD'), plan: item.next_plan.translations[$store.getters.curlocale], period: options.planPeriodsKeyed[item.next_plan_period]}) }}</v-col>
                </template>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>{{ trans('fields.common.locale') }}</v-list-item-subtitle>
                        <v-list-item-title>{{ item.locale }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ trans('fields.common.landings') }}</v-list-item-subtitle>
                                <v-list-item-title>{{ item.landings_count }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ trans('fields.common.domains') }}</v-list-item-subtitle>
                                <v-list-item-title>{{ item.domains_count }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ trans('fields.common.links') }}</v-list-item-subtitle>
                                <v-list-item-title>{{ item.links_count }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ trans('fields.common.offers') }}</v-list-item-subtitle>
                                <v-list-item-title>{{ item.offers_count }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-list-item v-if="item.vendor_id">
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ trans('fields.common.cpaNetwork') }}</v-list-item-subtitle>
                                <v-list-item-title>{{ item.vendor.label }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-list-item v-if="item.master_id">
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ trans('fields.common.master') }}</v-list-item-subtitle>
                                <v-list-item-title>
                                    <user-link :user="item.master"></user-link>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-btn
                    v-if="id"
                    color="primary"
                    outlined
                    @click="editing = true"
            >
                <v-icon left>mdi-pencil-outline</v-icon>
                {{ trans('buttons.common.edit') }}
            </v-btn>
        </v-card-actions>
    </v-card>
    <user-form v-else v-on:saved="initialize" v-on:updated="initialize" v-on:close="editing = false" :item-id="id"></user-form>
</template>

<script>
    import itemForm from "@/mixins/itemForm";

    export default {
        name: "UserCard",
        components: {
            UserLink: () => import("@/components/admin/user/UserLink"),
            PlanValidTill: () => import("@/components/user/billing/PlanValidTill"),
            UserForm: () => import("@/components/admin/user/UserForm"),
        },
        mixins: [itemForm],
        data(){
            return {
                showRoute: 'admin.users.show',
                updateRoute: 'admin.users.update',
                storeRoute: 'admin.users.store',

                preloadOptions: {
                    planPeriods: true,
                }
            }
        },
    }
</script>

<style scoped>

</style>